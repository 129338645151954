import { AxiosResponse } from 'axios';
import { TelegramAuthData } from '@telegram-auth/react';

import { AuthorizationResType, CheckAuthorizationResType } from './types';

import { request } from '../axios';

export const authService = {
  authorization: async (body: TelegramAuthData) => {
    return request.post<TelegramAuthData, AxiosResponse<AuthorizationResType>>('/auth', body).then((res) => res.data);
  },
  checkAuthorization: async () => {
    return request.get<void, AxiosResponse<CheckAuthorizationResType>>('/auth').then((res) => res.data);
  },
  logout: async () => {
    return request.post(`/auth/logout`).then((res) => res.data);
  },
};
