import { Menu as AntMenu, MenuProps } from 'antd';
import { useTranslation } from 'react-i18next';

import { Link } from 'react-router-dom';

import { useTelegram } from '../../../features/hooks/useTelegram';
import {
  ADMIN,
  BUYER,
  LINK_TO_CHANNELS,
  LINK_TO_CHANNELS_STATISTICS,
  LINK_TO_DICTIONARY,
  LINK_TO_MAIN,
  LINK_TO_MARGIN_REPORT,
  LINK_TO_ORDERS,
  LINK_TO_PAYMENT_CONFIRMATION,
  LINK_TO_PRICES,
  LINK_TO_PRICE_CALCULATOR,
  LINK_TO_REPORT_ACCOUNTING,
  LINK_TO_REPORT_ON_BUYER,
  LINK_TO_ROLE_MANAGEMENT,
  PERMISSION_VIEW_ACCEPT_TRANSACTIONS,
  PERMISSION_VIEW_CALC_PRICES,
  PERMISSION_VIEW_CHANNEL_PRICES,
  PERMISSION_VIEW_DICTIONARY,
  PERMISSION_VIEW_MARGIN_REPORT,
  PERMISSION_VIEW_ORDERS_AND_VIEW_ORDER,
  PERMISSION_VIEW_PERMISSIONS,
  PERMISSION_VIEW_REPORT_ACCOUNTING,
  PROGRAMMER,
  SUPER_ADMIN,
} from '../../../features/constants';
import { checkPermission } from '../../../features/helpers/check-permission';
import { useAppSelector } from '../../../features/hooks/useRedux';
import { selectAuth } from '../../../features/store/slices/auth';

import { checkRole } from 'features/helpers/checkRole';

type MenuItem = Required<MenuProps>['items'][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: 'group',
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    type,
  } as MenuItem;
}

export const Menu = ({ onSubmitToLink }: { onSubmitToLink: () => void }) => {
  const { tg } = useTelegram();
  const { user } = useAppSelector(selectAuth);
  const { t } = useTranslation('common');

  const browserMenu: MenuProps['items'] = [
    getItem(
      <Link to={LINK_TO_MAIN} onClick={onSubmitToLink}>
        {t('sidebar.main')}
      </Link>,
      'home',
    ),
    checkPermission({ permissionId: PERMISSION_VIEW_ORDERS_AND_VIEW_ORDER, user: user! })
      ? getItem(
          <Link to={LINK_TO_ORDERS} onClick={onSubmitToLink}>
            {t('sidebar.orders')}
          </Link>,
          'orders',
        )
      : null,

    getItem(
      <Link to={LINK_TO_CHANNELS} onClick={onSubmitToLink}>
        {t('sidebar.channels')}
      </Link>,
      'channels',
    ),

    checkPermission({ permissionId: PERMISSION_VIEW_CHANNEL_PRICES, user: user! })
      ? getItem(
          <Link to={LINK_TO_PRICE_CALCULATOR} onClick={onSubmitToLink}>
            {t('sidebar.channel_prices')}
          </Link>,
          'channel_prices',
        )
      : null,
    checkPermission({ permissionId: PERMISSION_VIEW_DICTIONARY, user: user! })
      ? getItem(
          <Link to={LINK_TO_DICTIONARY} onClick={onSubmitToLink}>
            {t('sidebar.dictionary')}
          </Link>,
          'dictionary',
        )
      : null,

    getItem(t('sidebar.reports'), 'reports', null, [
      checkPermission({ permissionId: PERMISSION_VIEW_MARGIN_REPORT, user: user! })
        ? getItem(
            <Link to={LINK_TO_MARGIN_REPORT} onClick={onSubmitToLink}>
              {t('sidebar.margin_report')}
            </Link>,
            'margin_report',
          )
        : null,
      checkPermission({ permissionId: PERMISSION_VIEW_REPORT_ACCOUNTING, user: user! })
        ? getItem(
            <Link to={LINK_TO_REPORT_ACCOUNTING} onClick={onSubmitToLink}>
              {t('sidebar.report_accounting')}
            </Link>,
            'report_accounting',
          )
        : null,
      getItem(
        <Link to={LINK_TO_CHANNELS_STATISTICS} onClick={onSubmitToLink}>
          {t('sidebar.report_on_channels')}
        </Link>,
        'report_on_channels',
      ),
      checkRole({ roleId: [SUPER_ADMIN, ADMIN, BUYER, PROGRAMMER], user: user! })
        ? getItem(
            <Link to={LINK_TO_REPORT_ON_BUYER} onClick={onSubmitToLink}>
              {t('sidebar.report_on_buyers')}
            </Link>,
            'report_on_buyers',
          )
        : null,
      checkPermission({ permissionId: PERMISSION_VIEW_CALC_PRICES, user: user! })
        ? getItem(
            <Link to={LINK_TO_PRICES} onClick={onSubmitToLink}>
              {t('sidebar.price_calculation')}
            </Link>,
            'prices',
          )
        : null,
    ]),
    getItem(t('sidebar.admin'), 'admin', null, [
      checkPermission({ permissionId: PERMISSION_VIEW_ACCEPT_TRANSACTIONS, user: user! })
        ? getItem(
            <Link to={LINK_TO_PAYMENT_CONFIRMATION} onClick={onSubmitToLink}>
              {t('sidebar.payment_confirmations')}
            </Link>,
            'payment_confirmations',
          )
        : null,
      checkPermission({ permissionId: PERMISSION_VIEW_PERMISSIONS, user: user! })
        ? getItem(
            <Link to={LINK_TO_ROLE_MANAGEMENT} onClick={onSubmitToLink}>
              {t('sidebar.role_management')}
            </Link>,
            'role-management',
          )
        : null,
    ]),
  ];

  const mobileMenu: MenuProps['items'] = [
    getItem(
      <Link to={LINK_TO_MAIN} onClick={onSubmitToLink}>
        {t('sidebar.main')}
      </Link>,
      'home',
    ),
    checkPermission({ permissionId: PERMISSION_VIEW_ORDERS_AND_VIEW_ORDER, user: user! })
      ? getItem(
          <Link to={LINK_TO_ORDERS} onClick={onSubmitToLink}>
            {t('sidebar.orders')}
          </Link>,
          'orders',
        )
      : null,

    getItem(
      <Link to={LINK_TO_CHANNELS} onClick={onSubmitToLink}>
        {t('sidebar.channels')}
      </Link>,
      'channels',
    ),

    checkPermission({ permissionId: PERMISSION_VIEW_CHANNEL_PRICES, user: user! })
      ? getItem(
          <Link to={LINK_TO_PRICE_CALCULATOR} onClick={onSubmitToLink}>
            {t('sidebar.channel_prices')}
          </Link>,
          'channel_prices',
        )
      : null,
    checkPermission({ permissionId: PERMISSION_VIEW_DICTIONARY, user: user! })
      ? getItem(
          <Link to={LINK_TO_DICTIONARY} onClick={onSubmitToLink}>
            {t('sidebar.dictionary')}
          </Link>,
          'dictionary',
        )
      : null,
    getItem(
      <a href={'/'} target="_blank" rel="noreferrer">
        {t('sidebar.desktop_version')}
      </a>,
      'desktop_version',
    ),
  ];

  return (
    <AntMenu
      style={{ width: '100%' }}
      defaultSelectedKeys={['1']}
      defaultOpenKeys={['sub1']}
      mode="inline"
      items={tg.initDataUnsafe.user ? mobileMenu : browserMenu}
    />
  );
};
