import { createSlice } from '@reduxjs/toolkit';

import { RootState } from '../store';

import { UserType } from '../../../types/users';

import type { PayloadAction } from '@reduxjs/toolkit';

interface AuthState {
  user: UserType | null;
}

const initialState: AuthState = {
  user: null,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<UserType>) => {
      state.user = action.payload;
    },
    logoutUser: (state) => {
      state.user = null;
    },
  },
});

export const authActions = authSlice.actions;

export const selectAuth = (state: RootState) => state.auth;

export default authSlice.reducer;
