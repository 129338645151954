import { LoadingOutlined } from '@ant-design/icons';

interface LoaderProps {
  inline?: boolean;
}

export const Loader: React.FC<LoaderProps> = ({ inline = false }) => {
  return (
    <div
      className={`${
        inline ? 'flex justify-start items-center' : 'min-h-screen w-full flex justify-center items-center'
      }`}
    >
      <LoadingOutlined />
    </div>
  );
};
