export function useTelegram() {
  const tg = (window as any).Telegram?.WebApp;

  const onClose = () => {
    tg.close();
  };

  tg.MainButton.hide();

  if (tg?.initDataUnsafe?.user) {
    if (window.location.search || window.location.pathname !== '/') {
      tg.BackButton.show();
    } else {
      tg.BackButton.hide();
    }
  }

  return {
    onClose,
    tg,
    initData: tg?.initDataUnsafe,
  };
}
