import { useQuery } from '@tanstack/react-query';

import { authService } from '../service';

import { QUERY_KEY_CHECK_AUTH } from 'api/keys';

export const useCheckAuthorization = () => {
  return useQuery({
    queryKey: [QUERY_KEY_CHECK_AUTH],
    queryFn: () => authService.checkAuthorization(),
    retry: false,
  });
};
