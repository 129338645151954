import { RouterProvider } from 'react-router-dom';
import { Suspense } from 'react';
import { ConfigProvider } from 'antd';
import dayjs from 'dayjs';

import ruLocale from 'antd/locale/ru_RU';

import { router } from './features/router';
import { Loader } from './components/loader';

import { CheckAuthorization } from 'components/layouts/check-authorization';

dayjs.locale('ru', { weekStart: 1 });

export const App = () => {
  console.log(`App started`);
  return (
    <Suspense fallback={<Loader />}>
      <ConfigProvider locale={ruLocale}>
        <CheckAuthorization>
          <RouterProvider router={router} />
        </CheckAuthorization>
      </ConfigProvider>
    </Suspense>
  );
};
