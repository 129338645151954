import { Button } from 'antd';

import { useTranslation } from 'react-i18next';

import { useLogout } from 'api/auth';
import { useActions } from 'features/hooks/useRedux';

export const Logout = () => {
  const { t } = useTranslation('common');

  const { mutateAsync: logout, isPending: isPendingLogout } = useLogout();
  const { logoutUser } = useActions();

  const handleLogout = async () => {
    try {
      await logout();
      logoutUser();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Button disabled={isPendingLogout} danger onClick={handleLogout}>
      {t('leave')}
    </Button>
  );
};
