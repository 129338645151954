import { Outlet } from 'react-router-dom';

import { Header } from 'components/common/header';

export const SideBarLayout = () => {
  return (
    <div>
      <Header />
      <div className="container">
        {/* Outlet заменяет children */}
        <Outlet />
      </div>
    </div>
  );
};
