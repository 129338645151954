import { useMutation } from '@tanstack/react-query';
import { TelegramAuthData } from '@telegram-auth/react';

import { authService } from '../service';

export const useAuthorization = () => {
  return useMutation({
    mutationFn: (data: TelegramAuthData) => authService.authorization(data),
  });
};
