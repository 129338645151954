import { LoginButton, TelegramAuthData } from '@telegram-auth/react';
import { toast } from 'react-toastify';

import { useEffect } from 'react';

import { BOT_NAME_DEV, BOT_NAME_PROD, isDev } from 'features/constants';
import { useActions, useAppSelector } from 'features/hooks/useRedux';
import { useAuthorization } from 'api/auth';
import { selectAuth } from 'features/store/slices/auth';
import { useCheckAuthorization } from 'api/auth/hooks/useCheckAuthorization';
import { Loader } from 'components/loader';

const botName = isDev ? BOT_NAME_DEV : BOT_NAME_PROD;

export const CheckAuthorization = ({ children }: { children: React.ReactNode }) => {
  const { mutateAsync: authorization, isPending: isPendingAuthorization } = useAuthorization();
  const { data: checkAuthData, isPending: isPendingCheckAuth } = useCheckAuthorization();

  const { user } = useAppSelector(selectAuth);

  const { setUser } = useActions();

  const getTokenCallback = async (data: TelegramAuthData) => {
    try {
      if (!data) {
        return toast('Данные о пользователе telegram не получены', {
          type: 'error',
        });
      }

      const tokens = await authorization({
        auth_date: data.auth_date,
        first_name: data.first_name,
        hash: data.hash,
        id: data.id,
        last_name: data.last_name,
        photo_url: data.photo_url,
        username: data.username,
      });

      if (tokens && tokens.user) {
        setUser(tokens.user);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (checkAuthData) {
      setUser(checkAuthData.user);
    }
  }, [checkAuthData]);

  if (isPendingCheckAuth || isPendingAuthorization) {
    return <Loader />;
  }

  if (user) {
    return <div>{children}</div>;
  }

  return (
    <div className="w-full max-w-[96%] h-screen flex flex-col items-center justify-center">
      <div className="mx-2 w-full max-w-[400px] p-4 rounded-lg border-[1px] border-[#54aaeb63] flex flex-col items-center ">
        <div>
          <img className="w-full max-w-[220px] rounded-[50%]" src="/logo_tgads.png" alt="" />
        </div>
        <div className="my-4 text-xl font-semibold">TG Ads</div>
        <LoginButton
          botUsername={botName}
          authCallbackUrl={undefined}
          buttonSize="large"
          cornerRadius={5}
          showAvatar={true}
          lang="ru"
          onAuthCallback={getTokenCallback}
        />
        {isPendingAuthorization && 'Loading...'}
      </div>
    </div>
  );
};
