import { UserType } from '../../types/users';

interface CheckPermissionProps {
  user: UserType;
  roleId: string | string[];
}

export const checkRole = ({ user, roleId }: CheckPermissionProps) => {
  if (Array.isArray(roleId)) {
    return roleId.some((id) => id === user.roleId);
  }

  return user?.roleId === roleId;
};
